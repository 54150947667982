import { i18n } from '@/plugins/i18n';

export default [
  {
    value: '',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionPlaceholder'),
  },
  {
    value: 'tijd',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionTime'),
  },
  {
    value: 'duur',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionExpensive'),
  },
  {
    value: 'sportschool',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionGym'),
  },
  {
    value: 'buitenlucht',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionOutside'),
  },
  {
    value: 'doelbereikt',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionGoalAchieved'),
  },
  {
    value: 'resultaattijd',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionResultsTime'),
  },
  {
    value: 'ziekblessure',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionInjury'),
  },
  {
    value: 'verwachtingen',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionExpectations'),
  },
  {
    value: 'platform',
    name: i18n.global.t('contractQuestionnaire.stopReasonOptionPlatform'),
  },
];