<template>
  <div class="contractQuestionnairePage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <base-heading type="4">
              {{ $t('contractQuestionnaire.title') }}
            </base-heading>

            <p>
              {{
                $t('contractQuestionnaire.body', {
                  name: member.name,
                  phonenumber: '+31202801339',
                })
              }}
            </p>

            <br />

            <p>
              <strong>{{ $t('contractQuestionnaire.titleExtra') }}</strong><br />
            </p>

            <Form
              v-bind:validation-schema="validationSchema"
              v-slot="{ handleSubmit, isSubmitting }"
              as="div"
            >
              <base-form v-on:submit="handleSubmit($event, onSubmit)">
                <base-form-element>
                  <base-form-label for-id="reason">
                    {{ $t('contractQuestionnaire.stopReasonOptionPlaceholder') }}
                  </base-form-label>

                  <base-form-select
                    id="reason"
                    v-bind:options="reasonOptions"
                  />

                  <base-form-element-error name="reason" />
                </base-form-element>

                <base-form-element>
                  <base-form-label for-id="feedback">
                    {{ $t('contractQuestionnaire.feedbackPlaceholder') }}
                  </base-form-label>

                  <base-form-input
                    type="text"
                    id="feedback"
                  />
                </base-form-element>

                <div class="contractQuestionnairePage__buttons">
                  <base-form-element class="contractQuestionnairePage__buttonField">
                    <base-button
                      modifiers="secondary block fullWidth"
                      v-bind:to="{ name: 'profile.overview' }"
                      v-on:click.prevent="() => {}"
                    >
                      {{ $t('contractQuestionnaire.backButtonLabel') }}
                    </base-button>
                  </base-form-element>

                  <base-form-element class="contractQuestionnairePage__buttonField">
                    <base-button
                      v-bind:show-spinner="isSubmitting"
                      v-bind:disabled="isSubmitting"
                      modifiers="primary block fullWidth"
                    >
                      {{ $t('contractQuestionnaire.continueButtonLabel') }}
                    </base-button>
                  </base-form-element>
                </div>
              </base-form>
            </Form>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseFormSelect from '@/components/forms/BaseFormSelect';
import BaseButton from '@/components/BaseButton';
import BaseHeading from '@/components/BaseHeading';
import stopMembershipReasons from '@/data/stopMembershipReasons';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseForm,
    BaseFormElement,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseFormSelect,
    BaseButton,
    BaseHeading,
  },

  data() {
    return {
      reasonOptions: stopMembershipReasons,
      validationSchema: yup.object({
        reason: yup
          .string()
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  computed: {
    ...mapState('member', ['member']),
  },

  methods: {
    async onSubmit(values) {
      try {
        await this.$store.dispatch('user/submitQuestionnaire', values);

        this.$router.push({ name: 'contract.summary' });
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.contractQuestionnairePage {
  padding: 20px 0;
}

.contractQuestionnairePage__buttons {
  @include desktop {
    display: flex;
    justify-content: space-between;
    gap: rem(16px);
  }
}

.contractQuestionnairePage__buttonField {
  flex: 1 1 auto;
}
</style>